import { Media, ResponsiveImage } from 'components';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
//import tailwindConfig from 'tailwind.config.js';
import Expand from 'assets/expand.svg';

const HoleDetails = ({ data }) => {
  // const [showVideo, setShowVideo] = useState(0);
  const [infoPanel, setInfoPanel] = useState(false);

  //const media = useRef();

  useEffect(() => {
    // const onResize = () => {
    //   setShowVideo(window.innerWidth >= parseInt(tailwindConfig.theme.screens.sm, 10));
    // };
    // onResize();
    setInfoPanel(true);
    // window.addEventListener('resize', onResize);
    // return () => {
    //   window.removeEventListener('resize', onResize);
    // };
  }, []);

  // const openFullscreen = () => {
  //   media?.current?.openFullscreen();
  // };

  const close = () => {
    setInfoPanel(!infoPanel);
  };

  return (
    <div
      data={data}
      className="font-montserrat text-brand-black relative h-[844px] md:h-[928px] lg:h-screen lg:max-h-[800px]"
    >
      <div className="absolute top-0 left-0 right-0 h-full w-full gradient-left-to-right" />
      <div className={classNames('absolute h-full w-full overflow-hidden')}>
        <Media
          media={data.mediaItemDay}
          widths={{ xs: 327, sm: 640, md: 1024, lg: 1024, xl: 1440, xxl: 2560 }}
          heights={{ xs: 844, sm: 640, md: 928, lg: 928, xl: 800, xxl: 1440 }}
          cover
        />
      </div>
      <div className="w-full h-full relative">
        <div className="container mx-auto h-full flex content-end justify-end flex-wrap">
          <div className="relative lg:px-0 w-1/2 h-full lg:w-[512px] py-10">
            <button
              className="z-50 btn small absolute right-0 top-[40px] p-5 h-auto bg-[#BCAB5D] text-xs"
              onClick={() => {
                close();
              }}
            >
              <span>Show info</span>
              <Expand />
            </button>
            <div
              className={`flex flex-col justify-between justify-end gap-y-6  backdrop-blur-[50px] bg-[#FFFEF980]  px-6 py-8 md:px-8 md:py-10 lg:p-10 font-medium 
                w-full h-full transition-all duration-700  origin-top-right
                ${infoPanel ? 'scale-1 opacity-1' : 'scale-0 opacity-0'}`}
              onClick={(e) => e.stopPropagation()}
            >
              {data.holeName && (
                <div
                  className={`transition-colors lg:whitespace-normal uppercase text-xl !leading-normal tracking-quote-reg-mobile md:tracking-button-reg `}
                >
                  {data.holeName}
                </div>
              )}
              <div className={`flex flex-col justify-between gap-y-6 h-fit  ${infoPanel ? 'block' : 'hidden'}`}>
                <ResponsiveImage
                  image={data.holeMap}
                  heights={{ xs: 120, md: 120, lg: 120, xl: 120 }}
                  widths={{ xs: 190, md: 190, lg: 190, xl: 190 }}
                  imgProps={{
                    className: 'w-1/2 object-contain ',
                    width: '190',
                    alt: 'logo',
                  }}
                  dataNotLazy
                />
                <div className=" text-sm uppercase flex grid grid-cols-2 gap-x-8 gap-y-4">
                  {data.holeDetails.map((detail, index) => (
                    <div key={index} className="flex justify-between border-b border-black/10 pb-2.5">
                      <span>{detail.detailName}</span>
                      <span className=" text-right">{detail.detailValue}</span>
                    </div>
                  ))}
                </div>
                {data.description && (
                  <div
                    className=" w-full color-from-bg description text-sm leading-6 mt-4 lg:-tracking-[0.14px]"
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HoleDetails;
