import React, { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { ModuleBase, HeadingTag } from '@/components';
import { openLink, useLayoutEffect } from '@/utils';
import classnames from 'classnames';
import GolfCourse from 'assets/golf_course.svg';
import HoleDetails from '@/modules/HoleDetails';

const HoleByHoleModule = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [underlineStyle, setUnderlineStyle] = useState({});

  const tabsContainer = useRef();
  const router = useRouter();

  useEffect(() => {
    if (data?.holes && activeIndex === null) {
      let newActiveIndex = null;
      const hash = router.asPath.split('#')[1];

      if (hash && hash.startsWith('t') && hash.length > 4) {
        const mid = hash.substring(1, 4);
        if (mid === data.moduleId.substring(0, 3)) {
          const tabIndex = hash.substring(4);
          if (!isNaN(tabIndex)) {
            newActiveIndex = Number(tabIndex);
          }
        }
      }

      if (newActiveIndex === null) {
        for (let i = 0; i < data.holes.length; i++) {
          newActiveIndex = i;
          break;
        }
      }
      console.log(newActiveIndex);
      setActiveIndex(newActiveIndex);
    }
  }, [data, router.asPath, activeIndex]);

  useEffect(() => {
    moveUnderline();
  }, [activeIndex]);

  useLayoutEffect(() => {
    window.addEventListener('resize', moveUnderline);
    return () => window.removeEventListener('resize', moveUnderline);
  }, []);

  const moveUnderline = () => {
    setTimeout(() => {
      if (tabsContainer?.current?.children) {
        Array.from(tabsContainer.current.children).forEach((element) => {
          if (element && element.classList && element.classList.contains('active-tab')) {
            let elementHalfWidth = element.offsetLeft + element.offsetWidth / 2 - 7;
            setUnderlineStyle({
              left: `${elementHalfWidth}px`,
            });
          }
        });
      }
    }, 0);
  };

  const tabClick = (tab, index) => {
    if (tab.tabLink) {
      openLink(router, tab.tabLink);
    } else {
      router.replace({
        hash: `t${data.moduleId.substring(0, 3)}${index}`,
      });
      setActiveIndex(index);
    }
  };

  return (
    <ModuleBase data={data} className="bg-background-primary">
      <div className="flex flex-col overflow-x-auto overflow-y-hidden scrollbar-hide font-montserrat ">
        <div className="flex flex-row items-center container pb-16">
          {data.heading && (
            <HeadingTag
              data={data.heading}
              className="w-2/5 font-didot color-from-bg whitespace-pre-wrap text-title-l not-italic font-normal leading-10 -tracking-headline-m-mobile
                            text-left md:text-title-xl md:leading-[44px] md:-tracking-headline-l-mobile
                            lg:text-[64px] lg:leading-[80px] lg:-tracking-headline-s [&_.indented]:ml-0 [&_.indented]:ml-11"
            />
          )}
          {data.text && (
            <div
              className="h-fit w-3/5 color-from-bg description text-base leading-6 mt-4 lg:-tracking-[0.14px]"
              dangerouslySetInnerHTML={{ __html: data.text }}
            />
          )}
        </div>
        {data.holes && (
          <div ref={tabsContainer} className="relative flex w-max md:w-full border-t border-[#76908080]">
            <div
              className="flex flex-col grow items-start justify-center border-r border-[#76908080] p-6 md:px-8 md:py-7 text-center font-montserrat transition-colors lg:whitespace-normal 
                font-bold uppercase text-xl !leading-normal tracking-quote-reg-mobile md:tracking-button-reg
                text-brand-black"
            >
              <GolfCourse></GolfCourse>
              <span>Holes</span>
            </div>
            {data.holes.map((tab, index) => (
              <div
                key={index}
                className={classnames(
                  'flex flex-col grow cursor-pointer items-center justify-center whitespace-nowrap  my-6 md:py-7 text-center font-montserrat transition-colors lg:whitespace-normal font-medium uppercase text-xs md:text-sm !leading-normal tracking-quote-reg-mobile md:tracking-button-reg',
                  activeIndex === index ? 'active-tab text-brand-black' : 'text-brand-black',
                )}
                onClick={() => tabClick(tab, index)}
                role="button"
              >
                {activeIndex === index && (
                  <div>
                    <span>H{index + 1}</span>
                    <div className="h-[38px] w-[38px] rounded-full mt-2.5 p-[12px] bg-[#475A4F]">
                      <div
                        className=" absolute top-50 transition-all duration-500 ease-out h-[14px] w-[14px] bg-[#FFFEF9] block rounded-full"
                        style={underlineStyle}
                      />
                    </div>
                  </div>
                )}
                {activeIndex !== index && (
                  <div>
                    <span className="mb-2.5">H{index + 1}</span>
                    <div className="h-[38px] w-[38px] rounded-full mt-2.5 p-[12px] bg-[#76908040]"></div>
                  </div>
                )}
              </div>
            ))}

            <div
              className="absolute bottom-0 h-px transition-all duration-500 ease-out bg-black"
              style={underlineStyle}
            />
          </div>
        )}
      </div>
      <div className="relative">
        {data.holes &&
          data.holes.map((tab, index) => (
            <div
              key={index}
              className={classnames(
                'top-0 pb-0 pt-0 transition-opacity duration-500 ease-out',
                activeIndex === index ? 'relative opacity-100' : 'absolute h-0 w-full overflow-hidden opacity-0',
              )}
            >
              <HoleDetails data={tab}></HoleDetails>
            </div>
          ))}
      </div>
    </ModuleBase>
  );
};

export default HoleByHoleModule;
